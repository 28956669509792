define("discourse/plugins/discourse-yearly-review/discourse/components/yearly-review-admin-notice", ["exports", "@glimmer/component", "@ember/template", "discourse/helpers/replace-emoji", "discourse-common/helpers/i18n", "discourse-common/lib/get-url", "discourse-i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _template, _replaceEmoji, _i18n, _getUrl, _discourseI18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.janNextYear = janNextYear;
  var _class;
  function janNextYear() {
    return new Date(new Date().getFullYear() + 1, 0, 1);
  }
  class YearlyReviewAdminNotice extends _component.default {
    get toBeCreatedDate() {
      return moment(janNextYear()).format(_discourseI18n.default.t("dates.full_with_year_no_time"));
    }
    get settingsUrl() {
      return (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=plugin%3Adiscourse-yearly-review");
    }
  }
  _exports.default = YearlyReviewAdminNotice;
  _class = YearlyReviewAdminNotice;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="yearly-review-admin-notice alert alert-info">
        {{replaceEmoji
          (htmlSafe
            (i18n
              "yearly_review.admin_notice"
              to_be_created_date=this.toBeCreatedDate
              settings_url=this.settingsUrl
            )
          )
        }}
      </div>
    
  */
  {
    "id": "uTJO6R3N",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"yearly-review-admin-notice alert alert-info\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[[28,[32,1],[[28,[32,2],[\"yearly_review.admin_notice\"],[[\"to_be_created_date\",\"settings_url\"],[[30,0,[\"toBeCreatedDate\"]],[30,0,[\"settingsUrl\"]]]]]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-yearly-review/discourse/components/yearly-review-admin-notice.js",
    "scope": () => [_replaceEmoji.default, _template.htmlSafe, _i18n.default],
    "isStrictMode": true
  }), _class);
});